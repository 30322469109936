import React from "react";
import { Button, ButtonGroup, Grid } from "@mui/material";

export function ButtonList({ buttonMap, clickEvent, buttonColor }: any) {
  const onClick = (e: any) => {
    clickEvent(e);
  };
  const elements = [];
  if (buttonMap) {
    for (const [key, value] of buttonMap.entries()) {
      elements.push(
        <Grid item xs={4} paddingLeft={1} paddingRight={1} key={key}>
          <Button
            sx={{
              minWidth: "100%",
              backgroundColor: value.selected ? "rgb(83 39 90)" : buttonColor,
              borderRadius: 20,
              textTransform: "none",
              boxShadow: "none",
            }}
            variant="contained"
            size="medium"
            color="secondary"
            value={key}
            onClick={onClick}
          >
            {" "}
            {key}
          </Button>
        </Grid>
      );
    }
  }

  return <Grid container>{elements}</Grid>;
}