import { Stack, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ButtonList } from "../common/buttonList";
import "../../styles/components/home/inputForm.css";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { wrapWithQuotes } from "../../utils/StringUtils";
import { useMediaQuery } from "@mui/material";
import MadeWithLoveSvgMobile from "../common/madeWithLoveSvgMobile";

type ButtonConfig = {
  selected: boolean;
};

const defaultButtonMap = new Map<string, ButtonConfig>();
defaultButtonMap.set("Cheesy", { selected: false });
defaultButtonMap.set("Naughty", { selected: false });
defaultButtonMap.set("Cute", { selected: false });

const InputForm: React.FC<{
  onSearchCallback: (data: any) => void;
  refetch: boolean;
}> = ({ onSearchCallback, refetch = false }) => {
  //TODO: move these options either to 1) constant file or 2) an API
  // list: ["Cheesy", "Naughty", "Cute"],
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [modes, setModes] =
    useState<Map<string, ButtonConfig>>(defaultButtonMap);
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(false);
  const onChangeName = (event: any) => {
    // console.log(event.target.value);
    setName(event.target.value);
  };

  const onChangeBio = (event: any) => {
    // console.log(event.target.value);
    setBio(event.target.value);
  };

  const completionAPISearch = async () => {
    const genneratedData = await axios.post(
      "https://flirt-ai-be-wnfihoa4gq-uc.a.run.app/generate",
      {
        name: name,
        interests: bio,
        type: mode,
      }
    );
    if (genneratedData.status === 200) {
      return genneratedData.data;
    } else {
      return null;
    }
  };
  const generatePickUpLines = async () => {
    setLoading(true);
    const completion = await completionAPISearch();
    // console.log(completion.data);
    onSearchCallback(wrapWithQuotes(completion.data.choices[0].text));
    setLoading(false);
  };
  const onSubmit = async () => {
    console.log(`submitted | name : ${name} | bio : ${bio}`);

    await generatePickUpLines();
  };

  const onChangeMode = (event: any) => {
    //console.log(event.target.value);
    const newMap = new Map<string, ButtonConfig>(defaultButtonMap);
    newMap.set(event.target.value, { selected: true });
    setModes(newMap);
    setMode(event.target.value);
  };
  useEffect(() => {
    if (refetch) {
      generatePickUpLines();
    }
  }, [refetch]);
  return (
    <>
      <Stack
        direction="column"
        spacing={3}
        padding={"2%"}
        paddingLeft={"10%"}
        paddingRight={"10%"}
      >
        <Typography align="center" variant="h5" component="h5">
          {" "}
          What's your flirt <span className="font-bold"> mode </span> ?{" "}
        </Typography>
        <ButtonList
          buttonMap={modes}
          clickEvent={onChangeMode}
          buttonColor="#FF03E6"
        />
        <TextField
          variant="outlined"
          required={true}
          type={"text"}
          color="secondary"
          sx={{
            backgroundColor: "#FFD7FB",
            borderColor: "#FF03E6",
            borderRadius: 2,
          }}
          inputProps={{ style: { color: "#FF03E6" } }}
          placeholder={"Name of the hooman you're trying to woo?"}
          onChange={onChangeName}
        />
        <TextField
          variant="outlined"
          required={true}
          rows={3}
          color="secondary"
          sx={{
            backgroundColor: "#FFD7FB",
            borderColor: "#FF03E6",
            borderRadius: 2,
          }}
          inputProps={{ style: { color: "#FF03E6" } }}
          multiline
          type={"text"}
          placeholder={
            "What are some of their  interests and about them that could inspire a steamy pickup line?"
          }
          onChange={onChangeBio}
        />
        <LoadingButton
          variant="contained"
          size="medium"
          color="secondary"
          sx={{
            width: "100%",
            paddingTop: 2,
            paddingBottom: 2,
            margin: 20,
            backgroundColor: "#B20CED",
            borderRadius: 4,
            textTransform: "none",
          }}
          onClick={onSubmit}
          loading={loading}
        >
          Generate a pickup line for me
        </LoadingButton>
        {isMobile && (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <MadeWithLoveSvgMobile />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default InputForm;
