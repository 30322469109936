import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";

export function Header({ backgroundColor = "#FFFFFF" }) {
  const isMobile = useMediaQuery("(max-width: 767px)");
  return (
    <>
      <Grid
        container
        direction={"column"}
        spacing={2}
        alignItems={isMobile ? "flex-start" : "center"}
        justifyContent={isMobile ? "flex-start" : "center"}
        sx={{
          paddingLeft: isMobile ? "20px" : "0px",
          backgroundColor: backgroundColor,
          marginLeft: 0,
        }}
      >
        <Grid item xs={12}>
          <div
            className="header-texts"
            style={{ textAlign: "left", marginTop: isMobile ? "0px" : "10%" }}
          >
            <img src="/Vector.svg" alt="flirt ai" />
            <h1 style={{ color: "#FF9AF5", fontWeight: 700 }}>
              Flirt smarter, <br />
              not harder
            </h1>
            <h1 style={{ color: "#B20CED" }}>
              let AI do the <br />
              work for you
            </h1>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
