import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  styled,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import React from "react";

type Props = {
  TextContent: string;
  onRegenerateClick: () => void;
};

const FlirtPrimaryButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#B20CED",
    color: "white",
  },
  borderRadius: "20px",
  textTransform: "none",
  padding: "6px 15px",
}));

const ButtonTypography = styled(Typography)(({ theme }) => ({
  padding: "8px 8px",
  color: "white",
}));

const DialogBox: React.FC<Props> = ({ TextContent, onRegenerateClick }) => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const onCopyClick = () => {
    navigator.clipboard.writeText(TextContent);
  };
  return (
    <div style={{}}>
      <Card
        sx={{
          maxWidth: 550,
          height: 550,
          background:
            "radial-gradient(76.58% 65.24% at 67.91% 16.03%, #FF03E6 0%, #B20CED 100%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "49px",
          padding: "16px",
          marginBottom: "5%",
          // add media queries to change the maxWidth and height properties on small screens
          "@media (max-width: 600px)": {
            maxWidth: "100%",
            height: "calc(60vw - 32px)",
            width: "calc(100vw - 32px)",
          },
          "@media (min-width: 600px) and (max-width: 960px)": {
            maxWidth: "80%",
            height: "40%",
          },
          "@media (min-width: 960px) and (max-width: 1280px)": {
            maxWidth: "600px",
            height: "300px",
          },
          "@media (min-width: 1280px)": {
            maxWidth: "600px",
            height: "350px",
          },
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={{
              fontSize: isMobile ? "20px" : "40px",
              fontFamily: "Inter",
              fontWeight: 400,
              color: "#FFFFFF",
              textAlign: "center",
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
            gutterBottom
          >
            {`${TextContent}`}
          </Typography>
        </CardContent>
        <CardActions>
          {/* <Button size="small">Learn More</Button> */}
        </CardActions>
      </Card>
      <Grid
        container
        direction="row"
        alignItems={"center"}
        justifyContent={"center"}
        spacing={1}
        sx={{}}
      >
        <FlirtPrimaryButton
          sx={{
            backgroundColor: "#B20CED",
            "&:hover": {
              backgroundColor: "#FF03E6",
              color: "white",
            },
            marginRight: "5%",
          }}
          onClick={onCopyClick}
        >
          <ButtonTypography>{`Copy`}</ButtonTypography>
        </FlirtPrimaryButton>
        <FlirtPrimaryButton
          sx={{
            backgroundColor: "#FF03E6",
          }}
          onClick={onRegenerateClick}
        >
          <ButtonTypography>Retry</ButtonTypography>
        </FlirtPrimaryButton>
      </Grid>
    </div>
  );
};

export default DialogBox;
