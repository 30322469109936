import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ResultBox from "../../components/home/resultBox";
import { Header } from "../../components/home/Header";
import InputForm from "../../components/home/inputForm";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";

const ResponsiveGrid = styled(Grid)``;

const Home: React.FC = () => {
  const [result, setResult] = useState("");
  const [refetchData, setRefetchData] = useState(false);
  const isMobile = useMediaQuery("(max-width: 767px)");

  const onFormSubmitCallback = (data: any) => {
    setResult(data);
    //TODO: useRedux might be better
    setRefetchData(false);
  };
  const onRegenerateClick = () => {
    setRefetchData(true);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ResponsiveGrid container spacing={0} columns={{ xs: 1, sm: 10 }}>
        <Grid className="side" item xs={12} sm={6}>
          <ResultBox
            textContent={
              result ? result : `"Your magical pickup line will pop up here"`
            }
            onRegenerateClick={onRegenerateClick}
          />
        </Grid>
        <Grid className="side" item xs={12} sm={4}>
          {!isMobile && (
            <Grid
              direction={"column"}
              alignItems="center"
              justifyContent="center"
              item
            >
              <Header />
            </Grid>
          )}
          <InputForm
            refetch={refetchData}
            onSearchCallback={onFormSubmitCallback}
          />
        </Grid>
      </ResponsiveGrid>
    </Box>
  );
};

export default Home;
