import { createTheme, styled } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Theme {
      status: {
        primary: string;
        secondary: string;
        textBoxPrimary: string;
      };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
      status?: {
        primary?: string;
        secondary?: string;
        textBoxPrimary?: string;
      };
    }
  }
  
  export const theme = createTheme({
    status: {
      primary: 'string',
      secondary: 'string',
      textBoxPrimary: 'string'
    },
  });