import React from "react";
import DialogBox from "./DialogBox";
import { Stack } from "@mui/material";
import MadeWithLoveSvg from "../common/madeWithLoveSvg";
import { useMediaQuery } from "@mui/material";
import { Header } from "./Header";

import styled from "styled-components";
type StackProps = {
  isMobile: boolean;
};
const MobileCSS = {
  backgroundColor: "#FFFFFF",
  justifyContent: "center",
};
const NormalCSS = {
  background:
    "radial-gradient(82.87% 81.75% at 17.13% 21.88%, #460CED 0%, #B20CED 100%)",
  justifyContent: "center",
};
const StyledStack = styled(Stack)<StackProps>`
  ${({ isMobile }) =>
    isMobile &&
    `
    & > :nth-child(2) {
      background: radial-gradient(82.87% 81.75% at 17.13% 21.88%, #460CED 0%, #B20CED 100%);
      width: 100%;
      padding: 8% 4% 8% 4%;
    }
  `}
`;

const ResultBox: React.FC<{
  textContent: string;
  onRegenerateClick: () => void;
}> = ({
  textContent = `"Your magical pickup line will pop up here"`,
  onRegenerateClick,
}) => {
  const isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <>
      <StyledStack
        direction="column"
        alignItems="center"
        height={isMobile ? "80vh" : "100vh"}
        spacing={3}
        sx={isMobile ? MobileCSS : NormalCSS}
        isMobile={isMobile}
      >
        {isMobile && <Header />}
        <DialogBox
          onRegenerateClick={onRegenerateClick}
          TextContent={textContent}
        />

        {!isMobile && (
          <Stack
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ minHeight: "200px" }}
          >
            <MadeWithLoveSvg />
          </Stack>
        )}
      </StyledStack>
    </>
  );
};

export default ResultBox;
